.virtualBtn {
   display: flex;
   align-items: center;
   margin-left: 12px;
   padding: 8px 24px;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   cursor: pointer;
}

.linkContainer {
   padding: 32px;
}

.imgContainer {
   display: flex;
   align-items: center;
   flex: 0 0 auto;
   justify-content: center;
   width: 24px;
   height: 24px;
   padding: 9px;
   border-radius: 50%;
   background: #F6F6F6;
}

.imgContainer img {
   width: 100%;
}