.imgMain {
   border: none;
   object-fit: cover;
   width: 100%;
   height: 400px;
   border-radius: 40px;
 }
 
 .mainTextContainer {
   max-width: 903px;
 }
 
 .filterText {
   color: #808080;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: -0.28px;
 }
 
 .textBody p:not(:first-child),
 .textBody blockquote:not(:first-child) {
   margin-top: 38px;
 }
 
 .maskContainer {
   margin-top: 8px;
   position: relative;
   border-radius: 12px;
   width: 144px;
   height: 96px;
   overflow: hidden;
   cursor: pointer;
 }
 
 .citationTextBody {
   box-sizing: border-box;
   padding-left: 40px;
   border-left: 2px solid #eeee20;
   color: #303030;
   font-size: 20px;
   font-style: italic;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: -0.4px;
 }
 
 .authorInfoContainer {
   margin-left: 40px;
 }
 
 .author {
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 600;
   line-height: 28px;
   letter-spacing: -0.4px;
 }
 
 .jobTitle {
   margin-top: 4px;
   color: #808080;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   letter-spacing: -0.32px;
 }
 
 .imgContainer {
   margin-top: 32px;
   display: flex;
   gap: 8px;
   flex-wrap: wrap;
 }
 
 img.img {
   width: 143.3px;
   height: 96px;
   border-radius: 12px;
   cursor: pointer;
 }
 
 @media screen and (max-width: 1225px) {
   .mobilMt32 {
     margin-top: 32px !important;
   }
 }
 
 @media screen and (max-width: 745px) {
   .mobileContainer {
     margin-top: 0;
   }
 
   .title {
     font-size: 24px;
   }
 
   .textBody {
     font-size: 16px;
   }
 
   .textBody p:not(:first-child) {
     margin-top: 24px;
   }
 
   .imgMain {
     height: 220px;
     border-radius: 24px;
   }
 }
 
 @media screen and (max-width: 495px) {
   .imgContainer {
     justify-content: center;
   }
 }
 
 @media screen and (max-width: 433px) {
   .img.img {
     width: 125px;
     height: 70px;
   }
 }
 