.link {
   text-decoration: none;
   /* display: flex;
   align-items: center !important; */
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: -0.32px;
}

.textInner {
   width: 276px;
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
}

.datePublication {
   color: #808080;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: -0.28px;
}

.documentIcon {
   margin-right: 12px;
   border-radius: 12px;
   padding: 12px;
}

.PDF {
   background: #E13D34;
}

.DOCX {
   background: #307CF1;
}

.documentContainer {
   display: flex;
   justify-content: space-between;
   align-items: center;
   cursor: pointer;
}