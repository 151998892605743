.cardBtnImgContainer img {
   width: 100%;
}

.cardBtnLink {
   margin-left: 8px;
   display: flex;
   width: 40px;
   height: 40px;
   justify-content: center;
   align-items: center;
   border-radius: 40px;
   background: #F6F6F6;
   cursor: pointer;
}

.subtitle {
   color: #212121;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 40px;
   letter-spacing: -0.56px;
}

.table:nth-child(1) {
   max-width: 90px;
}

.table:nth-child(2) {
   max-width: 180px;
}

.table:nth-child(4) {
   max-width: 407px;
}

.documentContainer>div:not(:first-child) {
   margin-top: 32px;
}