.cardBtnImgContainer img {
   width: 100%;
}

.cardBtnLink {
   margin-left: 8px;
   display: flex;
   width: 40px;
   height: 40px;
   justify-content: center;
   align-items: center;
   border-radius: 40px;
   background: #F6F6F6;
   cursor: pointer;
}

.linkContainer {
   padding: 32px;
}

.documentContainer>div:not(:first-child) {
   margin-top: 32px;
}