.imgContainer {
   width: 1015px;
   height: 400px;
   border-radius: 40px;
   overflow: hidden;
}

.imgContainer img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.flexContainer {
   display: flex;
   justify-content: space-between;
}

.titleInput {
   color: #808080;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 20px;
   letter-spacing: 0;
}

.input {
   display: block;
   width: 440px;
   padding: 16px;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   border-radius: 12px;
   border: 1px solid #ededed;
   box-sizing: border-box;
}

.input::-webkit-input-placeholder,
.textarea::-webkit-input-placeholder {
   color: #b9b9b9;
}

.input:hover,
.textarea:hover {
   border: 1px solid #B0B0B0;
}

input:focus,
.textarea:focus {
   border: 1px solid #8d8c8c;
   outline: none;
}


.input_min {
   width: 200px;
}

.flexInputMin {
   display: flex;
   justify-content: space-between;
   max-width: 450px;
}

.flexInputMin .input {
   width: 210px;
}

.line {
   width: 100%;
   height: 1px;
   background: #ededed;
}

.textarea {
   width: 900px;
   height: 208px;
   padding: 16px 24px;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   border-radius: 12px;
   border: 1px solid #ededed;
   resize: vertical;
   box-sizing: border-box;
}

.description {
   color: #808080;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
}

.submit {
   padding: 8px 24px;
   display: inline-block;
   color: #8c7e00;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   border-radius: 40px;
   background: #ffec00;
   cursor: pointer;
}

.descriptionContainer {
   align-items: start;
}

.descriptionContainer span {
   margin-left: 24px;
}

.messageContainer {
   position: fixed;
   bottom: 50px;
}

.important {
   position: absolute;
   bottom: 0;
   left: 0;
   opacity: 0;
}

@media screen and (max-width: 1145px) {

   .imgContainer,
   .input,
   .textarea {
      width: 100%;
   }

   .flexInputMin .input {
      width: 161px;
   }

   .flexContainer {
      flex-direction: column;
   }

   .flexInputMin .inputContainer,
   .flexContainer .inputContainer:not(:first-child) {
      margin-top: 32px;
   }
}

@media screen and (max-width: 745px) {
   .imgContainer {
      border-radius: 24px;
      height: 190px;
   }

   .descriptionContainer img {
      display: none;
   }

   .descriptionContainer span {
      margin: 0;
   }
}