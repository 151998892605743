.btn {
   white-space: nowrap;
   padding: 8px 24px;
   border-radius: 40px;
   background: #F6F6F6;
   font-size: 16px;
   font-weight: 400;
   line-height: 24px;
   color: #303030;
}

.btn:not(:first-child) {
   margin-left: 12px;
}

/* Активные кнопки на слайдере */
.btnActive {
   background: #FFEC00;
   color: #8C7E00;
}

@media screen and (max-width: 1225px) {
   .btn {
      font-size: 12px;
      padding: 8px 16px;
   }
}