.imgMain {
   object-fit: cover;
   width: 100%;
   height: 400px;
   border-radius: 40px;
}

.textBody {
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.textBody p:not(:first-child) {
   margin-top: 38px;
}

.maskContainer {
   margin-top: 8px;
   position: relative;
   border-radius: 12px;
   width: 144px;
   height: 96px;
   overflow: hidden;
   cursor: pointer;
}

.maskContainer:not(:first-child, :nth-child(7)) {
   margin-left: 8px;
}

.maskContainer:hover .pattern {
   display: none;
}

.description {
   color: #808080;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

@media screen and (max-width: 745px) {
   .imgMain {
      height: 220px;
      border-radius: 24px;
   }
}