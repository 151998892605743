.border {
   width: 100%;
   padding: 24px;
}

.spoilerItemHead {
   display: flex;
   justify-content: space-between;
   align-items: center;
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: -0.2px;
}

.btn {
   display: flex;
   align-items: center;
   padding: 16px;
   border-radius: 40px;
   background: #F6F6F6;
   cursor: pointer;
}

.spoilerCollapse {
   height: 0;
   overflow: hidden;
   transition: height 1s;
}

.flexCenter img {
   margin-left: 8px;
}

.rotate {
   transform: rotate(180deg);
}

@media screen and (max-width: 745px) {
   .btn {
      padding: 8px;
   }
}