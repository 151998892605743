.districtAboutContainer {
   width: 661px;
   height: 100%;
   display: flex;
   justify-content: space-between;
}

.container {
   width: 238px;
   display: flex;
   justify-content: space-between;
   flex-direction: column;
}

.container_buttom {
   justify-content: start;
}

.title {
   overflow: hidden;
   color: #303030;
   text-overflow: ellipsis;
   font-size: 20px;
   font-style: normal;
   font-weight: 600;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.title img {
   margin-left: 4px;
}

.textName {
   color: #303030;
   font-size: 24px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   letter-spacing: -0.48px;
}

.textName span {
   font-weight: 600;
}

.description {
   width: 240px;
   color: #808080;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: -0.24px;
}

.imgPerson {
   width: 96px;
   height: 96px;
   border-radius: 12px;
}

.imgPerson img {
   width: 100%;
   height: 100%;
}

.item li {
   margin-top: 4px;
}

.imgContainerMap {
   width: 245px;
   height: 200px;
   object-fit: contain;
}

.imgContainerMap img {
   width: 100%;
   height: 100%;
}

@media screen and (max-width: 745px) {
   .districtAboutContainer {
      width: 100%;
      height: auto;
   }

   .InfoFlexContainer {
      flex-direction: column;
      align-items: center;
      justify-content: center;
   }

   .imgContainer {
      margin: 32px 0 0 0;
   }

   .imgContainer {
      margin: 32px 0 0 0;
      width: 70%;
   }

   .districtAboutContainer {
      flex-direction: column;
      align-items: center;
   }

   .container:not(:first-child) {
      margin-top: 32px;
   }

   .container {
      width: calc(100% - 48px);
   }
}