body {
   margin: 0;
}

@font-face {
   font-family: 'Lato';
   font-style: normal;
   src: local('Lato Regular'), local('Lato-Regular'),
      url('../../assets/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
   font-family: 'Lato';
   font-style: normal;
   font-weight: 400;
   src: local('Lato Light'), local('Lato-Light'),
      url('../../assets/fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
   font-family: 'Lato';
   font-style: normal;
   font-weight: 700;
   src: local('Lato Bold'), local('Lato-Bold'),
      url('../../assets/fonts/Lato-Bold.ttf') format('truetype');
}

.banner_pb_wrapper * {
   box-sizing: border-box;
   font-family: 'Lato', sans-serif;
}

.banner_pb_wrapper {
   display: flex;
   justify-content: center;
   position: relative;
}

.banner_pb_container {
   width: 100%;
   /* padding: 30px 12px; */

   display: flex;
   flex-direction: column;
   align-items: center;
}

.banner_pb_content {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.banner_pb_content__link {
   margin-top: 20px;
   width: 100%;
   display: flex;
   justify-content: space-between;
}

.banner_pb_button {
   border-radius: 40px;
   padding: 8px 24px;
   color: #ffffff;
   font-weight: 700;
   font-size: 16px;
   line-height: 24px;
}


a {
   text-decoration: none;
}

.banner_pb_icon {
   width: 95px;
   object-fit: contain;
}

.banner_pb_icon img {
   width: 100%;
   height: 100%;
}

.banner_pb_content__info {
   margin: 20px 0;
   color: #000000;
}

.banner_pb_content__title {
   font-weight: 700;
   font-size: 17px;
   margin: 0 0 12px 0;
}

.banner_pb_content__subtitle {
   font-weight: 400;
   font-size: 16px;
   margin: 0;
}

.banner_pb_image {
   background-image: url('../../assets/iconsBanners/CityDay/business.svg');
   width: 200px;
   height: 203px;
   background-position: center;
   background-size: contain;
   background-repeat: no-repeat;
}

/* @media screen and (min-width: 444px) { */
.banner_pb_container {
   max-width: 444px;
}

.banner_pb_content {
   /* flex-direction: row; */
   padding: 20px 0 0 0;
   width: 100%;
   justify-content: space-between;
}

/* .banner_pb_content__link {
      width: auto;
      flex-direction: column;
   } */

/* .banner_pb_icon {
   padding: 18px 0 0 0;
   inline-size: fit-content;
} */

.banner_pb_content__info {
   margin: 0;
   /* width: 240px; */
}

/* } */

/* @media screen and (min-width: 554px) {
   .banner_pb_container {
      max-width: 554px;
      flex-direction: row-reverse;
      justify-content: space-between;
   }

   .banner_pb_image {
      width: 280px;
      height: 285px;
   }

   .banner_pb_content {
      flex-direction: column;
      width: 230px;
      padding: 0;
      align-items: flex-start;
   }

   .banner_pb_content__info {
      margin: 0 0 40px 0;
      width: 240px;
   }
}

@media screen and (min-width: 600px) {
   .banner_pb_container {
      max-width: 100%;
      padding: 30px 30px;
   }
}

@media screen and (min-width: 670px) {
   .banner_pb_container {
      max-width: 100%;
      padding: 20px 60px;
   }
}

@media screen and (min-width: 800px) {
   .banner_pb_container {
      width: 100%;
      max-width: 885px;
      padding: 0 60px;
   }

   .banner_pb_image {
      width: 320px;
      height: 250px;
      background-position: top;
      background-size: cover;
      margin: 20px 0 0 0;
   }

   .banner_pb_content {
      width: auto;
   }

   .banner_pb_content__info {
      width: 315px;
   }

   .banner_pb_content__title {
      font-size: 24px;
   }

   .banner_pb_content__subtitle {
      font-size: 18px;
   }

   .banner_pb_content__link {
      flex-direction: row;
   }

   .banner_pb_icon {
      padding: 8px 0 8px 20px;
   }
}

@media screen and (min-width: 940px) {
   .banner_pb_container {
      width: 100%;
      max-width: 100%;
      padding: 0 100px;
   }

   .banner_pb_image {
      width: 360px;
      height: 280px;
      background-position: top;
      background-size: cover;
      margin: 20px 0 0 0;
   }

   .banner_pb_icon {
      width: 180px;
      height: 68px;
      padding: 10px 0 10px 30px;
   }

   .banner_pb_content__info {
      width: 430px;
   }

   .banner_pb_content__title {
      font-size: 32px;
   }

   .banner_pb_content__subtitle {
      font-size: 28px;
   }

   .banner_pb_button {
      font-size: 20px;
      padding: 22px 45px;
   }
} */

/* @media screen and (min-width: 1920px) {
   .banner_pb_container {
      justify-content: center;
      max-width: 1440px;
      height: 260px;
      width: 100%;
   }

   .banner_pb_image {
      width: 310px;
      height: 240px;
      position: absolute;
      margin-left: 20px;
   }

   .banner_pb_content {
      width: 100%;
      flex-direction: row;
      align-items: center;
   }

   .banner_pb_content__info {
      margin: 0;
   }
} */

/* .bannerVirtual {
   text-decoration: none;
   display: flex;
   flex-direction: column;
   justify-content: end;
   background: url('../../assets/img/banners/city_day.png') 0 0/auto auto no-repeat;
   background-size: cover;
   padding: 40px;
   width: calc(100% - 80px);
   height: 370px;
   border-radius: 40px;
   overflow: hidden;
   cursor: pointer;
}

.title {
   display: inline-block;
   margin-top: 32px;
   font-size: 24px;
   letter-spacing: -0.01em;
   line-height: 28px;
   font-weight: 500;
   color: #FFFFFF;
}

.textBody {
   width: 245px;
   margin-top: 24px;
   font-size: 14px;
   letter-spacing: -0.01em;
   line-height: 20px;
   font-weight: 500;
   color: #FFFFFF;
   margin-bottom: 40px;
}

.button {
   width: 100%;
   padding: 8px 0;
   white-space: nowrap;
   display: inline-block;
   margin-top: 30px;
   text-align: center;
   border-radius: 40px;
   background-color: #fff;
   cursor: pointer;
}

@media screen and (max-width: 745px) {
   .bannerVirtual {
      border-radius: 24px;
   }
} */