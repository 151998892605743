.bannerContainer {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
   background-size: cover;
   width: 590px;
   height: 250px;
   overflow: hidden;
   cursor: pointer;
   padding: 40px;
   border-radius: 40px;
   text-decoration: none;
   background: #F6F6F6;
}

.title {
   display: inline-block;
   color: #303030;
   font-size: 24px;
   font-style: normal;
   font-weight: 500;
   line-height: 28px;
   letter-spacing: -0.48px;
}

.textBody {
   margin-top: 24px;
   color: #323232;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 20px;
   letter-spacing: -0.14px;
}

.button {
   margin-top: 30px;
   padding: 8px 24px;
   border-radius: 40px;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

@media screen and (max-width: 745px) {
   .bannerContainer {
      padding: 24px;
      height: auto;
   }
}