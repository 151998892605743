.container {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   padding: 24px;
   text-decoration: none;
   box-sizing: border-box;
   cursor: pointer;
   text-decoration: none;
}

.imgContainer {
   width: 277px;
   height: 160px;
   border-radius: 20px;
   overflow: hidden;
}

.imgContainer img {
   object-fit: cover;
   width: 100%;
   height: 100%;
   transition: all .4s ease-in-out;
}
.imgContainer img:hover {
   transform: scale(1.2);
}

.title {
   width: 277px;
   margin-top: 20px;
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
}

.btn {
   width: 160px;
   display: flex;
   align-items: center;
   margin-top: 73px;
   padding: 8px 24px;
   border-radius: 40px;
   border: 1px solid #B0B0B0;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.btn img {
   margin-left: 4px;
   width: 24px;
   height: 24px;
}