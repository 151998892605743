.item {
   width: 100%;
   height: 100%;
   border-radius: 40px;
   object-fit: contain;
}

.showMore {
   text-decoration: none;
   margin: 40px auto 0 auto;
   display: flex;
   width: 325px;
   padding: 24px 0;
   justify-content: center;
   align-items: center;
   border-radius: 40px;
   border: 1px solid #B0B0B0;
   cursor: pointer;
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.mobilCityDay {
   display: none;
}

.bannersContainerMini {
   display: flex;
   justify-content: center;
   flex-wrap: wrap;
   gap: 16px;
}

@media screen and (max-width: 1370px) {
   .mobilBannerContainer {
      display: none;
   }

   .mobilBannerRow {
      flex-wrap: wrap;
      gap: 16px;
   }

   .mobilStatistics {
      margin-top: 32px;
   }

   .mobilTGChannel {
      display: flex;
      justify-content: center;
      min-width: 100%;
      margin-top: 32px;
   }

   .mtCityDayBannerMobil {
      margin-top: 32px;
   }

   .mtGovernmentServicesBannerMobil {
      margin: 0;
   }
}

@media screen and (max-width: 745px) {
   .showMore {
      margin-top: 16px;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      width: 164px;
      padding: 12px 0;
   }

}

@media screen and (max-width: 500px) {
   .mobilCityDay {
      margin-top: 32px;
      display: block;
      width: 100%;
   }

   .mobilBannerContainer {
      display: block;
      margin-top: 20px;
   }
}

@media screen and (max-width: 366px) {
   .mobilBannerRow {
      justify-content: center;
   }
}