.bannerContainer {
   text-decoration: none;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
   padding: 40px;
   border-radius: 40px;
   background: url('../../assets/img/banners/vk_channel_mini.png') 0 0/auto auto no-repeat;
   background-size: cover;
   width: calc(325px - 80px);
   height: 317px;
   overflow: hidden;
   cursor: pointer;
}

.topContainer {
   display: flex;
   align-items: start;
   flex-direction: column;
}

.topContainer img {
   height: 80px;
}

.title {
   margin-top: 32px;
   display: inline-block;
   font-size: 24px;
   letter-spacing: -0.01em;
   line-height: 28px;
   font-weight: 500;
   color: #FFF;
}

.button {
   padding: 8px 24px;
   border-radius: 40px;
   background-color: #07F;
   font-size: 16px;
   line-height: 24px;
   color: #FFFFFF;
}

@media screen and (max-width: 775px) {
   .bannerContainer {
      border-radius: 24px;
      padding: 16px;
      height: 250px;
   }

   .topContainer img {
      height: 50px;
   }

   .title {
      line-height: 20px;
      font-size: 16px;
   }

   .textBody {
      width: auto;
      line-height: 16px;
      margin-top: 16px;
      font-size: 12px;
   }

   .button {
      font-size: 12px;
      padding: 4px 19px;
   }
}

@media screen and (max-width: 390px) {
   .bannerContainer {
      width: calc(100% - 48px);
   }
}