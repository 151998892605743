.imgMain {
   object-fit: cover;
   width: 100%;
   height: 400px;
   border-radius: 40px;
}

.mainTextContainer {
   max-width: 903px;
}

.textBody p:not(:first-child) {
   margin-top: 38px;
}

.btn {
   display: inline-flex;
   align-items: center;
   padding: 8px 24px;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   border-radius: 40px;
   background: #F6F6F6;
   text-decoration: none;
}
.btn img{
   margin-right: 4px;
}

.footnote{
   color: #808080;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

@media screen and (max-width: 745px) {
   .imgMain {
      height: 135px;
      border-radius: 20px;
   }
}