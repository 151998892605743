.personsContainerRow {
   display: flex;
   justify-content: space-between;
}

.nameTitle {
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 600;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.text {
   width: 900px;
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.text p {
   margin-top: 32px;
}

.btnText {
   padding: 8px 24px;
   display: inline-block;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   border-radius: 40px;
   background: #F6F6F6;
}

.btnContainerRow {
   display: flex;
}

.btn {
   display: inline-flex;
   margin-top: 40px;
   padding: 8px 24px 8px 24px;
   border-radius: 40px;

   font-size: 16px;
   font-weight: 400;
   line-height: 24px;
   color: #8C7E00;
   text-decoration: none;
}

.deacription{
   color: #808080;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}