.imgContainer {
   margin: 0 auto;
   width: 670px;
}

.imgContainer img {
   width: 100%;
   height: 100%;
   object-fit: contain;
}

.text {
   width: 580px;
   text-align: center;
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.btn {
   color: #8C7E00;
   padding: 8px 24px;
   border-radius: 40px;
   text-decoration: none;
}

.container {
   display: flex;
   flex-direction: column;
   align-items: center;
}

@media screen and (max-width: 745px) {
   .imgContainer {
      width: 100%;
   }

   .text {
      width: 100%;
      font-size: 16px;
   }

   .btn {
      padding: 8px 16px;
      font-size: 12px;
   }
}