.imgMain {
   border: none;
   object-fit: cover;
   width: 100%;
   height: 400px;
   border-radius: 40px;
}

.mainTextContainer {
   max-width: 903px;
}

.filterText {
   color: #808080;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: -0.28px;
}

.subtitle {
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 600;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.textBody p:not(:first-child),
.textBody blockquote:not(:first-child) {
   margin-top: 38px;
}

.mediaTitle {
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 600;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.imgContainer {
   margin-top: 30px;
   display: flex;
   flex-wrap: wrap;
}

.maskContainer {
   margin-top: 8px;
   position: relative;
   border-radius: 12px;
   width: 144px;
   height: 96px;
   overflow: hidden;
   cursor: pointer;
}

.maskContainer:not(:first-child, :nth-child(7)) {
   margin-left: 8px;
}

.maskContainer:hover .pattern {
   display: none;
}

.pattern {
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   background-color: #0000003D;
   z-index: 2;
   opacity: .7;
}

.img {
   position: absolute;
   object-fit: cover;
   left: 0%;
   top: 0%;
   width: 100%;
   height: 100%;
   z-index: 1;
}

blockquote {
   margin: 0;
   box-sizing: border-box;
   color: #303030;
   font-size: 20px;
   font-style: italic;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: -0.4px;
   padding-left: 40px;
   border-left: 2px solid #EEEE20;
}

@media screen and (max-width: 1225px) {
   .mobilMt32 {
      margin-top: 32px !important;
   }
}

@media screen and (max-width: 745px) {
   .mobileContainer {
      margin-top: 0;
   }

   .title {
      font-size: 24px;
   }

   .textBody {
      font-size: 16px;
   }

   .textBody p:not(:first-child) {
      margin-top: 24px;
   }

   blockquote {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.48px;
      padding-left: 21px;
   }

   .imgMain {
      height: 220px;
      border-radius: 24px;
   }
}