.breadcrumbs {
   cursor: pointer;
}

.breadcrumbsFrom {
   color: rgba(33, 33, 33, 0.56);
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.breadcrumbsTo {
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.cardBtnImgContainer img {
   width: 100%;
}

.time {
   display: flex;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

/* .text{
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 28px; 
   letter-spacing: -0.4px;
}

.title{
   color: #212121;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 40px;
   letter-spacing: -0.56px;
} */