.linkContainer {
   padding: 32px;
}

.containtContainer {
   max-width: 1015px;
}

.description {
   color: #808080;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}