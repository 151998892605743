.imgMainContainer {
   width: 100%;
   height: 400px;
   overflow: hidden;
   border-radius: 40px;
}

.imgMain {
   object-fit: cover;
   width: 100%;
   height: 100%;
}

.mainTextContainer {
   max-width: 903px;
}

.filterText {
   color: #808080;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: -0.28px;
}

.title {
   color: #212121;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 40px;
   letter-spacing: -0.56px;
}

.subtitle {
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 600;
   line-height: 28px; /* 140% */
   letter-spacing: -0.4px;
}

.btn {
   border-radius: 12px;
   display: inline-block;
   padding: 9px 18px;
   color: #fff;
   font-size: 20px;
   font-style: normal;
   font-weight: 600;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.lime {
   background: #42b6af;
}

.cian {
   background: #51aadf;
}

.orange {
   background: #faa919;
}

.red {
   background: #ee424f;
}

.green {
   background: #62ba46;
}

.blue {
   background: #1072b9;
}

@media screen and (max-width: 745px) {
   .imgMainContainer {
      border-radius: 24px;
      height: 190px;
   }
}
