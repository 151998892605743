.item {
   width: 100%;
   height: 100%;
   border-radius: 40px;
   object-fit: contain;
}

.title {
   width: 400px;
   font-size: 32px;
   font-weight: 500;
   line-height: 40px;
   letter-spacing: -0.02em;
}

.subtitle {
   margin-top: 29px;
   font-size: 24px;
   font-weight: 500;
   line-height: 28px;
   letter-spacing: -0.01em;
   text-align: left;
   color: #303030;
}

.textBody {
   margin-top: 33px;
   width: 505px;
   font-size: 16px;
   font-weight: 400;
   line-height: 24px;
}

.buttonGovernment {
   display: flex;
   margin-top: 40px;
   padding: 8px 24px 8px 24px;
   border-radius: 40px;

   font-size: 16px;
   font-weight: 400;
   line-height: 24px;
   color: #8C7E00;
}

.buttonGovernment img {
   margin-left: 4px;
}

.imgPortrainContainer {
   width: 290px;
   height: 290px;
   border-radius: 20px;
   overflow: hidden;
}

.imgPortrainContainer img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.histotyContainer {
   display: flex;
}

.column {
   display: flex;
   flex-direction: column;
   line-height: 24px;
}

.column:not(:first-child) {
   margin-left: 20px;
}

@media screen and (max-width: 1370px) {
   .histotyContainer {
      flex-direction: column;
   }

   .mobilContainer,
   .mobilContainer a:not(:first-child) {
      margin-top: 32px;
   }

   .column:not(:first-child) {
      margin: 20px 0px 0px 0px;
   }
}