.bannerContainer {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
   background: url('../../assets/img/banners/tg_channel.png') 0 0/auto auto no-repeat;
   background-size: cover;
   width: calc(100% - 80px);
   height: 370px;
   overflow: hidden;
   padding: 40px;
   border-radius: 40px;
   cursor: pointer;
   text-decoration: none;
}

.topContainer {
   display: flex;
   flex-direction: column;
   align-items: start;
}

.topContainer img {
   height: 50px;
}


.title {
   margin-top: 16px;
   display: inline-block;
   color: #FFF;
   font-size: 22px;
   font-style: normal;
   font-weight: 500;
   line-height: 28px;
   letter-spacing: -0.24px;
}

.textBody {
   width: 245px;
   margin-top: 24px;
   font-size: 14px;
   letter-spacing: -0.01em;
   line-height: 20px;
   font-weight: 500;
   color: #323232;
}

.button {
   padding: 8px 24px;
   border-radius: 40px;
   background: #019CE6;
   color: #FFF;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.button:hover{
   color: #323232;
}