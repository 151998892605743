.breadcrumbs {
   cursor: pointer;
}

.breadcrumbsFrom {
   color: rgba(33, 33, 33, 0.56);
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.breadcrumbsTo {
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.title {
   color: #8C7E00;
   font-family: 'Prata', serif;
   font-style: normal;
   font-weight: 400;
   line-height: 72px;
}

.imgMain {
   object-fit: cover;
   width: 100%;
   height: 400px;
   border-radius: 40px;
}

.mainTextContainer {
   max-width: 903px;
}

.subtitle {
   color: #8C7E00;
   font-family: 'Prata', serif;
   font-size: 28px;
   font-style: normal;
   font-weight: 400;
   line-height: 40px;
   letter-spacing: -0.56px;
}

/* .textBody,
.textBody * {
   color: #303030;
   font-size: 19px;
   font-style: normal;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: -0.4px;
} */

/* .textBody p:not(:first-child) {
   margin-top: 38px;
} */

@media screen and (max-width: 745px) {
   .imgMain {
      height: 192px;
      border-radius: 20px;
   }

   .subtitle {
      font-size: 24px;
   }
}