.linkContainer {
   padding: 32px;
}

.imgContainerBlock {

}

.imgContainerBlock img {
   width: 100%;
}