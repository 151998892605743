.container {
   display: flex;
}

.imgContainer {
   width: 64px;
   height: 64px;
}

.imgContainer img {
   width: 100%;
}

.description {
   color: #808080;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   letter-spacing: -0.28px;
}

.text {
   width: 900px;
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.imgScanContainer {
   width: 670px;
}

.imgScanContainer img {
   width: 100%;
}

.link {
   text-decoration: none;
}