.card {
   display: flex;
}

.portrainContainer {
   overflow: hidden;
   width: 285px;
   height: 271px;
   border-radius: 24px;
}

.portrainContainer img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.rightContainer {
   margin-left: 24px;
   padding: 0 20px;
}

.jobTitle {
   width: 870px;
   color: #303030;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 40px;
   letter-spacing: -0.56px;
}

.cardSurname {
   color: #303030;
   font-size: 24px;
   font-style: normal;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: -0.48px;
}

.cardName {
   color: #303030;
   font-size: 24px;
   font-style: normal;
   font-weight: 600;
   line-height: 32px;
   letter-spacing: -0.48px;
}

.subtitle {
   width: 890px;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.cardMoreDetails {
   margin-right: 10px;
   color: #808080;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.time {
   display: flex;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.cardBtnRow {
   display: flex;
}

.cardTel:not(:first-child) {
   margin-left: 16px;
}

.btn {
   padding: 8px 24px;
   border-radius: 40px;
   display: inline-block;
   color: #FFF;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.item:not(:first-child) {
   margin-top: 8px;
}

.item img {
   margin-right: 8px;
}

.columnContainer {
   width: 600px;
   display: flex;
   justify-content: space-between;
}

@media screen and (max-width: 1370px) {
   .card {
      flex-direction: column;
      align-items: center;
   }

   .jobTitle {
      width: 100%;
      margin-top: 14px;
      font-size: 16px;
      line-height: 20px;
   }

   .rightContainer {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
   }

   .btn {
      width: calc(100% - 48px);
   }

   .columnContainer {
      width: 100%;
   }
}

@media screen and (max-width: 745px) {
   .rightContainer {
      margin: 0;
      padding: 0;
   }

   .portrainContainer {
      width: 100%;
      height: 190px;
   }

   .item{
      font-size: 12px;
      line-height: 16px;
   }

   .columnContainer {
      flex-direction: column;
   }

   .btn{
      font-size: 14px;
      line-height: 16px;
   }
}