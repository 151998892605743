.linkContainer {
   padding: 32px;
}

.link {
   padding: 9px 0;
   display: flex;
   align-items: center;
   justify-content: space-between;
   text-decoration: none;
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: -0.2px;
   cursor: pointer;
}

.description{
   color: #808080;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: -0.28px;
}

@media screen and (max-width: 745px) {
   .link {
      font-size: 16px;
      line-height: 20px;
   }
}