.imgMain {
   object-fit: cover;
   width: 100%;
   height: 400px;
   border-radius: 40px;
}

.mainTextContainer {
   max-width: 903px;
}

.btn {
   display: inline-block;
   padding: 8px 24px;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   border-radius: 40px;
   background: #F6F6F6;
   text-decoration: none;
}

.imgContainer {
   display: flex;
}

.imgContainer .imgMain {
   height: 400px;
}

.imgContainer .imgMain:first-child {
   width: 400px;
}

.imgContainer .imgMain:last-child {
   margin-left: 20px;
   width: 555px;
}

@media screen and (max-width: 970px) {
   .imgContainer {
      width: 100%;
      height: 192px;
   }

   .imgContainer .imgMain {
      height: 192px;
   }

   .imgContainer .imgMain:first-child,
   .imgContainer .imgMain:last-child {
      width: 50%;
   }
}

@media screen and (max-width: 745px) {
   .imgMain {
      height: 192px;
      border-radius: 20px;
   }
}