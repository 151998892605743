.card {
   text-decoration: none;
   display: inline-block;
   padding: 0 24px 24px 24px;
}

.imgContainer {
   overflow: hidden;
   width: 260px;
   height: 260px;
   border-radius: 24px;
}

.imgContainer img {
   width: 100%;
}

.textContainer {
   width: 260px;
}

.jobTitle {
   width: 260px;
   height: 59px;
   color: rgba(48, 48, 48, 0.80);
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: -0.24px;
}

.jobTitleTop {
   width: 250px;
   height: auto;
}

.nameContainer {
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 600;
   line-height: 24px;
   letter-spacing: -0.2px;
}

.name {
   font-weight: 400;
}

@media screen and (max-width: 745px) {
   .card {
      width: 100%;
      /* display: flex;
      flex-direction: column;
      align-items: center; */
   }

   .imgContainer {
      width: 100%;
      height: 190px;
   }
}