.slideContainer {
   display: flex;
   flex-direction: column;
   align-items: start;
   justify-content: space-between;
   position: relative;
   min-width: 100%;
   height: 100%;
}

.slideContainer img {
   width: 100%;
   height: 100%;
}

.slideContantContainer {
   padding: 40px;
}

.slideContainer img {
   position: absolute;
   top: 0;
   left: 0;
   object-fit: cover;
   z-index: -1;
}

.slideTitle {
   display: flex;
   flex-direction: column;
   font-size: 44px;
   font-style: normal;
   font-weight: 600;
   line-height: 48px;
   letter-spacing: -2%;
}

.slideDescription {
   padding: 17px 0;
   color: #212121;
   font-size: 20px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: -0.4px;
}

.btn {
   text-decoration: none;
   height: 26px;
   margin: 0 0 60px 40px;
   padding: 19px 50px;
   border-radius: 18px;
   background: #FFF;
   color: #212121;
   font-size: 22px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   letter-spacing: -0.44px;
   cursor: pointer;
}

.btn:hover {
   color: #808080;
}

.btn:active {
   background: #EDEDED;
}

@media screen and (max-width: 1225px) {
   .slideTitle {
      font-size: 28px;
      line-height: 32px;
      letter-spacing: -2%;
   }

   .btn {
      padding: 12px 24px;
      font-size: 14px;
      margin: 0 0 24px 24px;
   }

   .slideDescription {
      font-size: 14px;
   }
}

@media screen and (max-width: 500px){
.btn{
   /* margin: 0 0 24px 40px; */
}
}

@media screen and (max-width: 745px) {
   .slideContantContainer {
      padding: 24px;
   }
}