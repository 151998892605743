.imgMain {
   object-fit: cover;
   width: 100%;
   height: 400px;
   border-radius: 40px;
}

.mainTextContainer {
   max-width: 903px;
}

.subtitle {
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 600;
   line-height: 28px;
   letter-spacing: -0.4px;
}


.textBody {
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.textBody p:not(:first-child) {
   margin-top: 38px;
}

.portrainContainer img {
   width: 100%;
}

@media screen and (max-width: 745px) {
   .imgMain {
      height: 220px;
      border-radius: 24px;
   }
}