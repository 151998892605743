.breadcrumbs {
   cursor: pointer;
}

.breadcrumbsFrom {
   color: rgba(33, 33, 33, 0.56);
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.breadcrumbsTo {
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.cardTel {
   display: flex;
   align-items: center;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;

   padding: 8px 24px;
   border-radius: 40px;
   background: #F6F6F6;
}