.linkContainer {
   display: flex;
}

.column {
   width: 616px;
}

.column:not(:first-child) {
   margin-left: 64px;
}

@media screen and (max-width: 1370px) {
   .linkContainer {
      flex-direction: column;
      align-items: center;
   }

   .column:not(:first-child) {
      margin: 32px 0 0 0;
   }
}

@media screen and (max-width: 745px) {
   .column {
      width: 100%;
   }
}