.spoilerItemHead {
   display: flex;
   justify-content: space-between;
   align-items: center;
   color: #212121;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 40px;
   letter-spacing: -0.56px;
   margin-bottom: 1px;
}

.btn {
   display: flex;
   align-items: center;
   padding: 12px;
   color: #5B5B5B;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   letter-spacing: -0.32px;
   border-radius: 40px;
   border: 1px solid #B0B0B0;
   cursor: pointer;
}

.spoilerCollapse {
   height: 0;
   overflow: hidden;
   transition: height 1s;
}

.flexCenter img {
   margin-left: 8px;
}

.rotate {
   transform: rotate(180deg);
}

@media screen and (max-width: 745px) {
   .btn {
      padding: 5px;
   }

   .btn span {
      display: none;
   }

   .spoilerItemHead {
      font-size: 16px;
      line-height: 20px;
   }
}