.contactContainer {
   position: relative;
   width: 355px;
}

.titleRow {
   display: flex;
}

.imgContainer {
   flex: 0 0 auto;
   width: 48px;
   height: 48px;
   border-radius: 53px;
   background: #F6F6F6;
   overflow: hidden;
}

.imgContainer img {
   width: 100%;
   height: 100%;
}

.title {
   margin-left: 16px;
   color: #303030;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
}

.name {
   color: #303030;
   font-size: 24px;
   font-style: normal;
   font-weight: 600;
   line-height: 32px;
   letter-spacing: -0.48px;
}

.surname {
   color: #303030;
   font-size: 24px;
   font-style: normal;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: -0.48px;
}

.description {
   color: #808080;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.date {
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.btn {
   position: absolute;
   right: 40px;
   bottom: 40px;
   padding: 16px;
   cursor: pointer;
}

.btn img {
   width: 24px;
   height: 24px;
}

.btnContainer {
   display: flex;
   align-items: start;
   flex-direction: column;
   gap: 8px;
}

.tel {
   text-decoration: none;
}



@media screen and (max-width: 745px) {
   .title {
      font-size: 12px;
      line-height: 16px;
   }

   .name,
   .surname {
      font-size: 20px;
   }

   .description,
   .date {
      font-size: 12px;
   }

   .btnContainer {
      align-items: start;
   }

   .mobilMt8 {
      margin-top: 8px;
   }

   .btn {
      display: none;
   }
}