.newsWrapper {
   width: 100%;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   gap: 17px;
}

.newsContainer {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   width: 277px;
   height: 302px;
   border-radius: 40px;
   padding: 24px;
   text-decoration: none;
   cursor: pointer;
}

.preview {
   width: 277px;
   height: 160px;
   overflow: hidden;
   border-radius: 20px;
}

.preview img {
   transition: all .4s ease-in-out;
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.preview img:hover {
   transform: scale(1.2);
}

.filterText {
   color: #808080;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
}

.title {
   margin-top: 8px;
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
}

.moreDetails {
   margin-top: 8px;
   color: #808080;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
}

.moreDetails:hover {
   color: #303030;
   font-weight: 500;
}

@media screen and (max-width: 1370px) {
   .newsWrapper {
      margin-top: 32px;
   }
}

@media screen and (max-width: 745px) {
   .newsWrapper {
      flex-direction: column;
      align-items: center;
   }

   .newsContainer {
      width: calc(95% - 32px);
      height: auto;
      border-radius: 24px;
   }

   .previewMobilHidden {
      display: none;
   }

   .preview {
      width: 100%;
   }
}