.text {
   color: #303030;
   text-align: center;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.container {
   height: 100vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-decoration: none;
}

.btn {
   color: #8C7E00;
   padding: 8px 24px;
   border-radius: 40px;
   text-decoration: none;
}

@media screen and (max-width: 745px) {
   .btn {
      padding: 8px 16px;
      font-size: 12px;
   }
}