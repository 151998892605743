.bannerContainer {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
   background: url('../../assets/img/banners/anti_corruption_banner.png') 0 0/auto auto no-repeat;
   background-size: cover;
   width: calc(100% - 80px);
   height: 300px;
   overflow: hidden;
   cursor: pointer;
   padding: 40px;
   border-radius: 40px;
   text-decoration: none;
}

.title {
   display: inline-block;
   color: #303030;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 40px;
   letter-spacing: -0.56px;
}

.textBody {
   margin-top: 24px;
   font-size: 14px;
   letter-spacing: -0.01em;
   line-height: 20px;
   font-weight: 500;
   color: #fff;
}

.button {
   padding: 8px 24px;
   border-radius: 40px;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

@media screen and (max-width: 745px) {
   .bannerContainer {
      background: url('../../assets/img/banners/anti_corruption_banner_mobil.png') 0 -200px/auto auto no-repeat;
      width: calc(100% - 48px);
      padding: 24px;
   }
}