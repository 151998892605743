.bannerContainer {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
   background: url('../../assets/img/banners/performance_indicators.png') 0 0/auto auto no-repeat;
   background-size: cover;
   width: calc(100% - 80px);
   width: 590px;
   height: 250px;
   overflow: hidden;
   cursor: pointer;
   padding: 40px;
   border-radius: 40px;
   text-decoration: none;
}

.title {
   display: inline-block;
   font-size: 24px;
   letter-spacing: -0.02em;
   line-height: 28px;
   font-weight: 500;
   color: #fff;
}

.textBody {
   margin-top: 24px;
   font-size: 14px;
   letter-spacing: -0.01em;
   line-height: 20px;
   font-weight: 500;
   color: #fff;
}

.button {
   padding: 8px 24px;
   border-radius: 40px;
   font-size: 16px;
   line-height: 24px;
   color: #303030;
}