.description {
   color: #808080;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: -0.28px;
 }
 
 .contactsContainer {
   display: flex;
   gap: 10px;
 }
 