.linkContainer {
   padding: 32px;
}

.link {
   padding: 9px 0;
   display: flex;
   align-items: center;
   justify-content: space-between;
   text-decoration: none;
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: -0.2px;
   cursor: pointer;
}
.imgContainer {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 20px;
   padding: 9px;
   border-radius: 50%;
   background: #F6F6F6;
}

.imgContainer img {
   width: 100%;
}