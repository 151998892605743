.card {
   display: flex;
}

.portrainContainer {
   overflow: hidden;
   width: 285px;
   height: 285px;
   border-radius: 24px;
}

.portrainContainer img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.rightContainer {
   margin-left: 24px;
   padding: 0 20px;
}

.jobTitle {
   width: 900px;
   color: #303030;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 40px;
   letter-spacing: -0.56px;
}

.cardSurname {
   color: #303030;
   font-size: 24px;
   font-style: normal;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: -0.48px;
}

.cardName {
   color: #303030;
   font-size: 24px;
   font-style: normal;
   font-weight: 600;
   line-height: 32px;
   letter-spacing: -0.48px;
}

.subtitle {
   width: 890px;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.cardMoreDetails {
   margin-right: 10px;
   color: #808080;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.time {
   display: flex;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.cardBtnRow {
   display: flex;
   align-items: start;
   gap: 16px;
}

/* .cardTel:not(:first-child) {
   margin-left: 16px;
} */

.btn {
   padding: 8px 24px;
   border-radius: 40px;
   display: inline-block;
   color: #FFF;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   text-decoration: none;
}

.imgContainer {
   margin-left: 16px;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 40px;
   height: 40px;
   border-radius: 50%;
   background: #F6F6F6;
}

.link {
   text-decoration: none;
}

@media screen and (max-width: 1370px) {
   .card {
      flex-direction: column;
      align-items: center;
   }

   .jobTitle {
      width: 100%;
      text-align: center;
      font-size: 20px;
      line-height: 20px;
   }

   .cardName {
      font-size: 20px;
      line-height: 20px;
   }

   .cardSurname {
      font-size: 16px;
      line-height: 32px;
   }

   .subtitle {
      width: 100%;
   }

   .rightContainer {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
   }
}

@media screen and (max-width: 745px) {

   .rightContainer {
      margin: 24px 0 0 0;
      padding: 0;
      width: 100%;
   }

   .time {
      flex-direction: column;
      align-items: center;
   }

   .cardTel {
      font-size: 12px;
      padding: 8px 16px;
      line-height: 16px;
   }

   /* .cardTel:not(:first-child) {
      margin-left: 0px;
   } */

   .cardMoreDetails {
      font-size: 14px;
      line-height: 20px;
   }

   .btn {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
   }
}

@media screen and (max-width: 560px) {
   .cardBtnRow {
      flex-direction: column;
   }
}

@media screen and (max-width: 400px) {
   .card {
      align-items: start;
   }

   .portrainContainer {
      width: 100%;
      height: 172px;
   }

   .jobTitle {
      text-align: left;
   }

   .rightContainer,
   .time {
      align-items: start;
   }
}