.linkContainer {
   padding: 32px;
}

iframe {
   border-radius: 40px;
   width: 100%;
}

@media screen and (max-width: 745px) {
   .link {
      font-size: 16px;
      line-height: 16px;
   }

   iframe {
      height: 190px;
      border-radius: 24px !important;
   }
}