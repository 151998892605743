.imgContainer img {
   width: 100%;
}

.title {
   color: #212121;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 40px;
   letter-spacing: -0.56px;
}

.subTitle {
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 600;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.targetContainer {
   padding-left: 20px;
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.text {
   width: 900px;
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.text p {
   margin-top: 32px;
}

.btnText {
   padding: 8px 24px;
   display: inline-block;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   border-radius: 40px;
   background: #F6F6F6;
}

.btnContainerRow {
   display: flex;
}

.card {
   display: flex;
}

.portrainContainer {
   overflow: hidden;
   width: 285px;
   height: 285px;
   border-radius: 24px;
}

.rightContainer {
   margin-left: 24px;
   padding: 0 20px;
}

.cardSurname {
   color: #303030;
   font-size: 24px;
   font-style: normal;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: -0.48px;
}

.cardName {
   color: #303030;
   font-size: 24px;
   font-style: normal;
   font-weight: 600;
   line-height: 32px;
   letter-spacing: -0.48px;
}

.cardMoreDetails {
   display: flex;
   margin-right: 10px;
   color: #808080;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.link {
   text-decoration: none;
}
.time {
   display: flex;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

.cardTel:not(:first-child) {
   margin-left: 16px;
}