.linkContainer {
   padding: 32px;
}

.personsContainerRow {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   gap: 24px;
}

.nameTitle {
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 600;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.btnContainerRow {
   display: flex;
   flex-wrap: wrap;
   gap: 32px;
}

.description {
   color: #808080;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: -0.28px;
}

@media screen and (max-width: 720px) {
   .personsContainerRow {
      justify-content: center;
   }

   .link {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
   }

   .mobilMl {
      margin-left: 0;
   }
}

@media screen and (max-width: 460px) {
   .personsContainerRow {
      justify-content: start;
   }
}