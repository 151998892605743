.table:nth-child(1) {
   max-width: 240px;
}

.table:nth-child(2) {
   max-width: 240px;
}

.table:nth-child(3) {
   max-width: 240px;
}

.table:nth-child(4) {
   max-width: 688px;
}