.targetContainer {
   padding-left: 20px;
}

.title {
   color: #212121;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 40px;
   letter-spacing: -0.56px;
}

.text {
   width: 900px;
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.text p {
   margin-top: 32px;
}

.table:nth-child(1) {
   max-width: 50px;
}

.table:nth-child(2) {
   max-width: 180px;
}
