.linkContainer {
   padding: 32px;
}

.linkContainerColumn {
   display: flex;
   justify-content: space-between;
}

.logoContainer {
   width: 120px;
   height: 120px;
   border-radius: 20px;
}

.logoContainer img {
   width: 100%;
}

.link {
   display: flex;
   align-items: center;
   justify-content: space-between;
   text-decoration: none;
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: -0.2px;
   cursor: pointer;
}

.link_min {
   width: 580px;
}

.description {
   color: #808080;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: -0.28px;
}

.text {
   width: 355px;
   color: #303030;
   font-size: 24px;
   font-style: normal;
   font-weight: 500;
   line-height: 32px;
   letter-spacing: -0.48px;
}

@media screen and (max-width: 1370px) {
   .linkContainerColumn {
      flex-direction: column;
      align-items: center;
   }

   .linkContainerColumn>a:not(:first-child) {
      margin-top: 32px;
   }

   .textContainer {
      margin-top: 32px;
   }
}

@media screen and (max-width: 745px) {
   .link {
      font-size: 16px;
   }

   .mobilCardLink {
      align-items: center;
   }

   .logoContainer {
      height: auto;
   }

   .textContainer {
      margin: 0 0 0 16px;
   }

   .text {
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.48px;
   }

   .link_min {
      width: calc(100% - 48px);
   }

   .mobilArrowNone {
      display: none;
   }

}