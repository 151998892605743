.cardContainer {
   position: relative;
   display: flex;
   cursor: pointer;
   text-decoration: none;
}

.cardContainer:not(:first-child) {
   margin-top: 20px;
}

.textContainer {
   margin-left: 40px;
   margin-right: 120px;
   width: 310px;
}

.img {
   object-fit: cover;
   width: 120px;
   height: 120px;
   border-radius: 20px;
}

.title {
   height: 72px;
   color: #303030;
   font-size: 24px;
   font-style: normal;
   font-weight: 500;
   line-height: 32px;
   letter-spacing: -0.48px;
}

.textBody {
   height: 60px;
   color: #303030;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
}

.btn {
   position: absolute;
   right: 40px;
   bottom: 40px;
   padding: 16px;
}

.btn img {
   width: 24px;
   height: 24px;
}

@media screen and (max-width: 745px) {
   .cardContainer {
      justify-content: space-between;
   }

   .img {
      width: 80px;
      height: 80px;
      border-radius: 14px;
   }

   .title {
      height: auto;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.32px;
   }

   .textBody {
      height: auto;
      margin-top: 12px;
      font-size: 12px;
      line-height: 16px;
      -webkit-line-clamp: 5;
   }

   .btn {
      width: 0;
      display: none;
   }

   .textContainer {
      /* min-width: 60%; */
      width: 100%;
      margin: 0 0 0 16px;
   }
}
