.navLinkContainer {
   position: relative;
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   height: 48px;
   cursor: pointer;
}

.navLinkContainer:not(:first-child) {
   margin-top: 24px;
}

.contantContainer {
   display: flex;
   align-items: center;

}

.imgContainer {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 48px;
   height: 48px;
   border-radius: 50%;
   background: #F6F6F6;
   overflow: hidden;
}

.navLinkTitle {
   margin-left: 24px;
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: -0.4px;
}

.btn {
   position: absolute;
   right: 0;
   padding: 12px;
   box-sizing: border-box;
}

.btn img {
   width: 24px;
   height: 24px;
}

@media screen and (max-width: 745px) {
   .navLinkContainer {
      width: 90%;
      height: auto;
   }

   .navLinkTitle {
      font-size: 16px;
      letter-spacing: -0.32px;
   }

   .btn {
      padding: 8px;
   }
}

@media screen and (max-width: 443px) {
   .btn {
      display: none;
   }
}