.imgMain {
   object-fit: cover;
   width: 100%;
   height: 400px;
   border-radius: 40px;
}

.mainTextContainer {
   max-width: 903px;
}

.footnote {
   color: #808080;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
}

@media screen and (max-width: 745px) {
   .imgMain {
      height: 135px;
      border-radius: 20px;
   }
}