/* Баннер */
.banner {
   display: block;
   position: relative;
   /* cursor: pointer; */
   width: 325px;
   height: 400px;
   border-radius: 40px;
   overflow: hidden;
}

.banner img {
   object-fit: cover;
   width: 100%;
   height: 100%;
}

@media screen and (max-width: 775px) {
   .banner {
      width: 164px;
      height: 260px;
      border-radius: 24px;
   }
}

.bannerMin {
   height: 450px;
}

.bannerContainer {
   position: absolute;
   display: flex;
   justify-content: end;
   align-items: flex-end;
   width: calc(100% - 80px);
   height: calc(100% - 80px);
   padding: 40px;
}

.btn {
   border-radius: 50%;
   width: 68px;
   height: 68px;
   background: #ffff url('../../assets/icons/arrow_btn_link.svg') center center no-repeat;
   box-shadow: 0px 13px 33px 1px rgba(71, 71, 71, 0.24);
}

.btn:hover {
   background: #ffff url('../../assets/icons/arrow_btn_link_opacity.svg') 30px center no-repeat;
}

.btn:active {
   background: #EDEDED url('../../assets/icons/arrow_btn_link.svg') center center no-repeat;
}