.imgContainer {
   margin-top: 18px;
   display: flex;
   flex-wrap: wrap;
}

.img {
   width: 210px;
   height: 140px;
   border-radius: 12px;
   margin: 20px 20px 0 0;
}

@media screen and (max-width: 745px) {
   .imgContainer {
      justify-content: center;
   }
   .img{
      width: 125px;
      height: 70px;
   }
}