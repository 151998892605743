.linkContainer {
   padding: 32px;
}

.link {
   text-decoration: none;
   padding: 9px 0;
   display: flex;
   align-items: center;
   justify-content: space-between;
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: -0.2px;
   cursor: pointer;
}

.personsContainerRow {
   display: flex;
   justify-content: space-between;
}

.nameTitle {
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 600;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.text {
   width: 900px;
   color: #303030;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: -0.4px;
}

.text p {
   margin-top: 32px;
}

.btnText {
   padding: 8px 24px;
   display: inline-block;
   color: #303030;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px;
   border-radius: 40px;
   background: #F6F6F6;
}

.btnContainerRow {
   display: flex;
}

@media screen and (max-width: 745px) {
   .link {
      font-size: 16px;
      line-height: 20px;
   }
}